import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ROUTE_PATHS} from "Config/Router/Routes";
import {Link} from "react-router-dom";
import {AdminCrudList} from "Views/Shared/Crud/AdminCrudList";
import {getChildren} from "Api/Query/ChildrenQuery";
import {deleteChildren} from "Api/Mutation/ChildMutation";
import {SortArrows} from "../Shared/Crud/SortArrows";
import Select from "react-select";
import {CourseLevelSelect} from "../../Components/CourseLevel/CourseLevelSelect";

export const AdminKidsList: React.FC = () => {

    const [sortField, setSortField] = useState<string>('id')
    const [sortDirection, setSortDirection] = useState<string>('-')
    const [refreshSwitch, setRefreshSwitch] = useState<boolean>(false)
    const [customFiltersData, setCustomFiltersData] = useState<{ [key: string]: any }>({})
    const [customFiltersDataToApply, setCustomFiltersDataToApply] = useState<{ [key: string]: any }>({})
    const [courseLevelFilter, setCourseLevelFilter] = useState<any>(null)

    const applyFilterChanges = () => {
        const parsedFilters: any = {}

        if (customFiltersData.q) {
            parsedFilters['q'] = customFiltersData.q
        }

        if (courseLevelFilter) {
            parsedFilters['courseLevel'] = courseLevelFilter.value
        }

        setCustomFiltersDataToApply(parsedFilters)
    }

    const sortBy = (field: string, direction: string = '') => {
        const newSort = direction + field
        const currentSort = sortDirection + sortField

        if (newSort === currentSort && sortDirection === direction) {
            direction = direction === '-' ? '' : '-'
        }

        setSortDirection(direction)
        setSortField(field)
    }

    useEffect(() => {
        setRefreshSwitch(!refreshSwitch)
    }, [sortField, sortDirection]);

    const customHeader: any = () => {
        return (
            <>
                <th className={"align-middle"} key={1}>
                    <Link onClick={() => sortBy('name')} to={"#"}>NOMBRE NIÑO/A</Link>
                    <SortArrows field={'name'} sortField={sortField} sortDirection={sortDirection}/>
                </th>
                <th className={"align-middle"} key={2}>
                    <Link onClick={() => sortBy('number')} to={"#"}>Nº NIÑO</Link>
                    <SortArrows field={'number'} sortField={sortField} sortDirection={sortDirection}/>
                </th>
                <th className={"align-middle"} key={3}>
                    <Link onClick={() => sortBy('nif')} to={"#"}>DNI/NIE</Link>
                    <SortArrows field={'nif'} sortField={sortField} sortDirection={sortDirection}/>
                </th>
                <th className={"align-middle"} key={4}>
                    <Link onClick={() => sortBy('center')} to={"#"}>CENTRO</Link>
                    <SortArrows field={'center'} sortField={sortField} sortDirection={sortDirection}/>
                </th>
                <th className={"align-middle"} key={5}>
                    <Link onClick={() => sortBy('course')} to={"#"}>CURSO</Link>
                    <SortArrows field={'course'} sortField={sortField} sortDirection={sortDirection}/>
                </th>
                <th className={"align-middle"} key={6}>
                    <Link onClick={() => sortBy('tutor')} to={"#"}>TUTOR/A</Link>
                    <SortArrows field={'tutor'} sortField={sortField} sortDirection={sortDirection}/>
                </th>
                <th className={"align-middle"} key={7}>
                    ACCIONES
                </th>
            </>
        )
    }

    return (
        <RequireAuth>

            <AdminCrudList
                title={"Listado de Niños/as"}
                customHeader={customHeader()}
                refreshSwitch={refreshSwitch}
                sort={(sortDirection + sortField)}
                queryMethod={getChildren}
                queryName={"adminListChildren"}
                deleteMethod={deleteChildren}
                tableRow={(item: any, key: number) =>
                    <>
                        <td>
                            <Link to={ROUTE_PATHS.ADMIN_KIDS_EDIT.replace(":id", item.id)}>
                                {item.name} {item.surnames}
                            </Link>
                        </td>
                        <td>{item.number}</td>
                        <td>{item.nif}</td>
                        <td>{item.center}</td>
                        <td>{item.courseLevel.name}</td>
                        <td>
                            <p>{item.tutor.name}</p>
                            <p>Telf: {item.tutor.phoneNumbers}</p>
                        </td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm"}
                                href={ROUTE_PATHS.ADMIN_KIDS_EDIT.replace(":id", item.id)}
                            >
                                Modificar
                            </Button>
                        </td>
                    </>
                }
                customFiltersData={customFiltersDataToApply}
                customFilters={
                    <>
                        <Col xs={12} sm={6}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Buscar
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e: any) => {
                                        const value = e.target.value ?? ''
                                        setCustomFiltersData({...customFiltersData, q: value})
                                    }}
                                    value={customFiltersData.q ?? ''}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={3}>
                            <Form.Group controlId={"queryFilter"}>
                                <Form.Label>
                                    Curso
                                </Form.Label>
                                <CourseLevelSelect
                                    onChange={(option: any) => setCourseLevelFilter(option ?? null)}
                                    value={courseLevelFilter}
                                />
                                {/*<Select*/}
                                {/*    placeholder={"Selecciona una opción"}*/}
                                {/*    options={validatedFilterOptions}*/}
                                {/*    value={validatedFilter}*/}
                                {/*    onChange={(e: any) => {*/}
                                {/*        setValidatedFilter(e)*/}

                                {/*        if (e.value !== null) {*/}
                                {/*            setCustomFiltersData({...customFiltersData, validated: e.value})*/}
                                {/*        } else {*/}
                                {/*            let newFilters: any = {}*/}
                                {/*            Object.keys(customFiltersData).map((key: string) => {*/}
                                {/*                if (key !== 'validated') {*/}
                                {/*                    newFilters = {...newFilters, key: customFiltersData[key]}*/}
                                {/*                }*/}
                                {/*            })*/}
                                {/*            setCustomFiltersData(newFilters)*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </Form.Group>
                        </Col>
                        <Row className={"mt-2"}>
                            <Col>
                                <Button
                                    className={"purple-btn"}
                                    onClick={applyFilterChanges}
                                >Buscar</Button>
                                &nbsp;
                                <Button
                                    variant={"secondary"}
                                    onClick={() => {
                                        setCourseLevelFilter(null)
                                        setCustomFiltersData({})
                                        setCustomFiltersDataToApply({})
                                    }
                                    }
                                >Limpiar filtros</Button>
                            </Col>
                        </Row>
                    </>
                }
                createButton={
                    <>
                        {/*<Button*/}
                        {/*    className={"purple-btn"}*/}
                        {/*    href={"#"}*/}
                        {/*>Añadir niño</Button>*/}
                    </>
                }
            />

        </RequireAuth>
    )
}
