import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {AdminCrudList} from "../Shared/Crud/AdminCrudList";
import {Button} from "react-bootstrap";
import {getTutors} from "../../Api/Query/TutorQuery";
import {Link} from "react-router-dom";
import {ROUTE_PATHS} from "../../Config/Router/Routes";
import {SortArrows} from "../Shared/Crud/SortArrows";

export const AdminTutorList: React.FC = () => {

    const [sortField, setSortField] = useState<string>('id')
    const [sortDirection, setSortDirection] = useState<string>('-')
    const [refreshSwitch, setRefreshSwitch] = useState<boolean>(false)

    const sortBy = (field: string, direction: string = '') => {
        const newSort = direction + field
        const currentSort = sortDirection + sortField

        if(newSort === currentSort && sortDirection === direction){
            direction = direction === '-' ? '' : '-'
        }

        setSortDirection(direction)
        setSortField(field)
    }

    useEffect(() => {
        setRefreshSwitch(!refreshSwitch)
    }, [sortField, sortDirection]);

    const customHeader : any = () => {
        return (
            <>
                <th className={"align-middle"} key={1}>
                    <Link onClick={() => sortBy('name')} to={"#"}>NOMBRE</Link>
                    <SortArrows field={'name'} sortField={sortField} sortDirection={sortDirection} />
                </th>
                <th className={"align-middle"} key={2}>
                    <Link onClick={() => sortBy('surnames')} to={"#"}>APELLIDOS</Link>
                    <SortArrows field={'surnames'} sortField={sortField} sortDirection={sortDirection} />
                </th>
                <th className={"align-middle"} key={3}>
                    HIJOS
                </th>
                <th className={"align-middle"} key={4}>
                    <Link onClick={() => sortBy('email')} to={"#"}>EMAIL</Link>
                    <SortArrows field={'email'} sortField={sortField} sortDirection={sortDirection} />
                </th>
                <th className={"align-middle"} key={5}>
                    DNIs
                </th>
                <th className={"align-middle"} key={6}>
                    TELÉFONOS
                </th>
            </>
        )
    }

    return (
        <RequireAuth>
        <AdminCrudList
                title={"Listado de tutores"}
                customHeader={customHeader()}
                sort={(sortDirection+sortField)}
                queryMethod={getTutors}
                queryName={"adminListTutors"}
                canSelectMultipleRows={false}
                refreshSwitch={refreshSwitch}
                deleteMethod={() => console.log(1)}
                tableRow={(item: any, key: number) =>
                    <>
                        <td>
                            <Link to={ROUTE_PATHS.ADMIN_TUTOR_EDIT.replace(":id", item.user.id)}>
                            {item.name}
                            </Link>
                        </td>
                        <td>{item.surnames}</td>
                        <td>
                            <ul style={{"padding": "0", "margin": 0}}>
                                {
                                    item.children.map((c: any, k: any) => {
                                        return (
                                            <li style={{"listStyle": "none"}} key={k}>· {c.name} {c.surname}</li>
                                        )
                                    })
                                }
                            </ul>
                        </td>
                        <td>{item.user.email}</td>
                        <td>
                            <ul style={{"padding": "0", "margin": 0}}>
                                {item.documents?.map((d: any, key: number) => <li style={{"listStyle": "none"}}
                                                                                  key={key}>· {d.document} ({d.fullname})</li>)}
                            </ul>
                        </td>
                        <td>
                            <ul style={{"padding": "0", "margin": 0}}>
                                {
                                    item.phones?.map((p: any, key: number) => {
                                        return <li style={{"listStyle": "none"}} key={key}>
                                            <ul style={{"padding": "0", "margin": 0}}>
                                                {p.phones.map((i: any) => <li style={{"listStyle": "none"}}>· {i} ({p.tutor})</li>)}
                                            </ul>
                                        </li>
                                    })
                                }
                            </ul>
                        </td>
                        <td className={"text-center align-middle"}>
                            <Button
                                className={"purple-btn btn-sm"}
                                href={ROUTE_PATHS.ADMIN_TUTOR_EDIT.replace(":id", item.user.id)}
                            >
                                Modificar
                            </Button>
                        </td>
                    </>
                }
            />

        </RequireAuth>
    )

}